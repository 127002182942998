import React from 'react'
import { graphql } from 'gatsby'
import { MyLayout, NameLink, PostLink } from '../components/common'

const Template = ({ data }) => {
    const edges = data.allMarkdownRemark.edges;
    return (
        <MyLayout>
            <NameLink />

            {edges.map((e, i) => {
                const frontmatter = e.node.frontmatter
                return (<PostLink key={i} frontmatter={frontmatter} />
                )
            })}

        </MyLayout>
    )
}

export const query = graphql`query AllQuery {
    allMarkdownRemark(
        sort: {order: DESC, fields: [frontmatter___date]}
    ) {
      edges {
        node{
          frontmatter { 
              title
              path
              excerpt
              date
        }
        }
      }
    }
  }`

export default Template
